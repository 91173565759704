import { Text, IconButton, Tooltip, Icon } from '@arcadiapower/shrike';
import { useCallback, useEffect, useState } from 'react';
import { formatPhoneNumber } from '@arcadiapower/warbler';
import { copyFor } from 'config/copy';
import { ARC_CREDENTIAL_STATUSES } from 'config/utility-credential';
import {
  formatServiceAddressForUtilityAccount,
  formatDerivedServices,
} from 'utils/formatters';
import { track, TrackEvents } from 'utils/analytics';
import { CredentialInformationModal } from './credential-information-modal.component';
import { DataField } from './data-field.component';
import {
  Section,
  Content,
  CustomerOverviewWrapper,
  DataWrapper,
  SectionTitleWrapper,
  SectionTitle,
  SectionSubtitle,
  SectionSubtitleText,
} from './customer-overview.style';

import { StatusPill } from '../../status-pill';

const getCopy = copyFor('customerOverview');

export type Props = {
  utilityAccount: NonNullable<GetUtilityAccountQuery['utilityAccount']>;
};

export const CREDENTIAL_STATUSES_WITH_INFORMATION_MODAL = [
  ARC_CREDENTIAL_STATUSES.error,
  ARC_CREDENTIAL_STATUSES.rejected,
  ARC_CREDENTIAL_STATUSES.pending,
] as const;

export const CustomerOverview = ({ utilityAccount }: Props): JSX.Element => {
  const [showCredentialModal, setShowCredentialModal] = useState(false);

  const { utilityCredential } = utilityAccount;

  useEffect(() => {
    if (utilityCredential)
      track(TrackEvents.CUSTOMER_OVERVIEW_VIEWED, {
        verificationStatus: utilityCredential.status,
      });
  }, [utilityCredential]);

  const renderSectionSubtitle = ({
    label,
    value,
    tooltipContent,
  }: {
    label: string;
    value?: Maybe<number | string>;
    tooltipContent: string;
  }) => (
    <SectionSubtitle>
      <SectionSubtitleText>
        {label} {value}
      </SectionSubtitleText>
      <Tooltip content={tooltipContent} tooltipId={label} label={label}>
        <Icon icon="InfoCircleInverted" color="secondary" opacity="medium" />
      </Tooltip>
    </SectionSubtitle>
  );

  const renderCredentialInformationButtonAndModal = useCallback(() => {
    const credentialStatusForInformationModal =
      CREDENTIAL_STATUSES_WITH_INFORMATION_MODAL.find(
        status => status === utilityCredential?.status
      );

    if (utilityCredential && credentialStatusForInformationModal) {
      const getModalCopy = copyFor(
        `customerOverview.credentialInformationModal.modalContent.${credentialStatusForInformationModal}`
      );
      return (
        <>
          <IconButton
            aria-label={getCopy('credentialInformationModal.buttonLabel')}
            icon="Wrench"
            onClick={() => {
              track(TrackEvents.CREDENTIAL_STATUS_INFORMATION_MODAL_LAUNCHED, {
                verificationStatus: utilityCredential.status,
              });
              setShowCredentialModal(true);
            }}
          />
          {showCredentialModal && (
            <CredentialInformationModal
              onClose={() => setShowCredentialModal(false)}
              copy={{
                title: getModalCopy('title'),
                contentTitle: getModalCopy('contentTitle'),
                description: getModalCopy('description', {
                  utility: utilityCredential.utility.derivedName,
                  utilityCredentialId: utilityCredential.id,
                }),
              }}
            />
          )}
        </>
      );
    }
  }, [showCredentialModal, utilityCredential]);

  return (
    <CustomerOverviewWrapper data-testid="customer-overview">
      <Section>
        <SectionTitleWrapper equalHeightPadding>
          <Text textStyle="heading800">
            {utilityAccount.serviceCustomerName ||
              getCopy('missingCustomerName')}
          </Text>
          {renderSectionSubtitle({
            label: getCopy('customerInformation.clientUserIdLabel'),
            value: utilityAccount.user.tenantUserId,
            tooltipContent: getCopy('customerInformation.clientUserIdTooltip'),
          })}
        </SectionTitleWrapper>
      </Section>

      <Section data-testid="utilityCredential">
        <SectionTitleWrapper>
          <SectionTitle>{getCopy('utilityCredential.title')}</SectionTitle>
          {renderSectionSubtitle({
            label: getCopy('utilityCredential.utilityCredentialIdLabel'),
            value: utilityCredential?.id,
            tooltipContent: getCopy(
              'utilityCredential.utilityCredentialIdTooltip'
            ),
          })}
        </SectionTitleWrapper>
        <Content>
          <DataField
            label={getCopy('utilityCredential.username')}
            value={utilityCredential?.username}
          />

          <DataField
            label={getCopy('utilityCredential.utility')}
            value={utilityCredential?.utility?.name}
          />
          <DataField
            label={getCopy('utilityCredential.utilityId')}
            value={utilityCredential?.utility?.id}
          />
          <DataField label={getCopy('utilityCredential.credentialsStatus')}>
            <DataWrapper>
              <StatusPill
                status={utilityCredential?.status}
                variant="credential"
              />
              {renderCredentialInformationButtonAndModal()}
            </DataWrapper>
          </DataField>
        </Content>
      </Section>

      <Section data-testid="utilityAccount">
        <SectionTitleWrapper>
          <SectionTitle>{getCopy('utilityAccount.title')}</SectionTitle>
          {renderSectionSubtitle({
            label: getCopy('utilityAccount.utilityAccountIdLabel'),
            value: utilityAccount.id,
            tooltipContent: getCopy('utilityAccount.utilityAccountIdTooltip'),
          })}
        </SectionTitleWrapper>
        <Content>
          <DataField
            label={getCopy('utilityAccount.accountNumber')}
            value={utilityAccount.accountNumber}
          />
          <DataField
            label={getCopy('utilityAccount.customerName')}
            value={utilityAccount.serviceCustomerName}
          />
          <DataField
            label={getCopy('utilityAccount.serviceAddress')}
            value={formatServiceAddressForUtilityAccount(utilityAccount)}
          />
          <DataField
            label={getCopy('utilityAccount.phoneNumber')}
            value={
              utilityAccount.scrapedPhoneNumber
                ? formatPhoneNumber(utilityAccount.scrapedPhoneNumber)
                : null
            }
          />
          <DataField
            label={getCopy('utilityAccount.serviceType')}
            value={formatDerivedServices(utilityAccount)}
          />
          <DataField label={getCopy('utilityAccount.accountStatus')}>
            <StatusPill variant="account" status={utilityAccount.status} />
          </DataField>
        </Content>
      </Section>
    </CustomerOverviewWrapper>
  );
};
